import classnames from "classnames"
import React from "react"
import * as styles from "./index.module.scss"
import Typography from "/src/components/ui/base/typography"
import { useTranslation } from "react-i18next"
import PhotoGallery from "../Gallery"
import { createMarkup, getDayPreviews } from "../../../../util/tour"
import {
  Accordion,
  AccordionItemButton,
  AccordionItemHeading,
} from "react-accessible-accordion"
import Link from "../Link"
import { DESTINATIONS_URL, PHOTO_TOURS_URL } from "../../../../util/constants"

const Day = ({ day, tour }) => {
  const { t, i18n: l } = useTranslation()
  const previews = getDayPreviews(day)

  return (
    <Accordion
      className={classnames([styles.days])}
      key={day.id}
      allowMultipleExpanded={false}
      allowZeroExpanded={true}
    >
      <div className={classnames([styles.day])}>
        <div className={classnames([styles.header])}>
          <div className={classnames([styles.left])}>
            <Typography
              variant="heading3"
              className={classnames([styles.number])}
            >
              {t("ui.components.base.Day.day", { number: day.number })}
            </Typography>
            <Typography
              variant="heading4"
              className={classnames([styles.title])}
            >
              <AccordionItemHeading className={classnames([styles.show_more])}>
                <AccordionItemButton>{day.title} →</AccordionItemButton>
              </AccordionItemHeading>
            </Typography>
          </div>
        </div>
        <div>
          <div className={classnames([styles.steps])}>
            {day.steps.map((step, index) => {
              return (
                <div key={step.title} className={classnames([styles.step])}>
                  <Typography variant="heading5">{step.title}</Typography>
                  <div
                    dangerouslySetInnerHTML={createMarkup(step.description)}
                  />

                  {step.locations.length > 0 && (
                    <div className={classnames([styles.locations])}>
                      <Typography variant="heading5">
                        {t("ui.components.base.Day.locations")}
                      </Typography>
                      {step.locations.map(location => {
                        return (
                          <div
                            key={location.title}
                            className={classnames([styles.location])}
                          >
                            <Typography
                              variant="heading5"
                              className={classnames([styles.name])}
                            >
                              <i className="icon-location" />{" "}
                              <Link
                                to={`/${PHOTO_TOURS_URL[l.language]}/${
                                  DESTINATIONS_URL[l.language]
                                }/${tour.states.slug}/${location.slug}/`}
                              >
                                {location.title}
                              </Link>
                            </Typography>
                            <Typography
                              variant="heading6"
                              className={classnames([styles.description])}
                            >
                              {location.description}
                            </Typography>
                            {location.pictures.map(picture => {
                              picture.image = picture.image.map(img => {
                                img.localFile = {
                                  ...img.localFile,
                                  childImageSharp: {
                                    ...img.localFile.childImageSharp,
                                    thumbAlt: img.name,
                                    title: img.name,
                                  },
                                }
                                return img
                              })
                              if (picture.type === "tour") {
                                return (
                                  <div
                                    key={`${picture.title}-pictureDay-${location.title}-${index}`}
                                    className={classnames([styles.images])}
                                  >
                                    <PhotoGallery data={picture.image} />
                                  </div>
                                )
                              } else {
                                return <></>
                              }
                            })}
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Accordion>
  )
}

export default Day
