import * as React from "react"
import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Typography from "/src/components/ui/base/typography"
import Header from "/src/components/ui/base/layout/header"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./index.module.scss"
import classnames from "classnames"
import SessionCard from "../../components/ui/base/SessionCard"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import Button from "/src/components/ui/base/Button"
import Faq from "../../components/ui/base/Faq"
import ReviewCard from "../../components/ui/base/ReviewCard"
import CoachCard from "../../components/ui/base/CoachCard"
import Day from "../../components/ui/base/Day"
import Section from "../../components/ui/base/Section"
import scrollTo from "gatsby-plugin-smoothscroll"
import "moment/locale/it"

import {
  createMarkup,
  getCondensedDays,
  getGalleryFromUsers,
  getTourBreadCrumbs,
  getTourCoaches,
  getTourDays,
  getTourLink,
  getTourNextDeparture,
  getTourPictures,
  getTourPrice,
} from "../../util/tour"
import Breadcrumbs from "../../components/ui/base/Breadcrumbs"
import useScrollPos from "../../hooks/useScrollPos"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { FAQJsonLd, ProductJsonLd } from "gatsby-plugin-next-seo"
import { getImagesURL, getStructuredDataTourReviews } from "../../util/seo"
import moment from "moment"
import { BASE_URL } from "../../util/constants"
import GallerySection from "../../components/ui/extended/GallerySection"
import SocialProofSection from "../../components/ui/extended/SocialProofSection"

const TourPage = ({ data }) => {
  const { t, i18n: l } = useTranslation()
  const [isScrolled, setScrolled] = React.useState(false)
  const tour = data.strapiTour
  const {
    price: tourPrice,
    deposit: tourDeposit,
    currency: tourCurrency,
  } = getTourPrice(tour)

  const tourCoaches = getTourCoaches(tour)
  const tourPictures = getTourPictures(tour)
  const tourDays = getCondensedDays(tour)
  const tourUsersPictures = getGalleryFromUsers(tour)
  const tourLink = l ? getTourLink(tour, l) : ""
  const absoluteTourLink = `${BASE_URL[l.language]}${tourLink}`

  const sessions = tour.sessions
    ?.filter(session => {
      return moment(session.start) > moment()
    })
    .sort((a, b) => {
      return new Date(a.start) - new Date(b.start)
    })

  const alternativeNavClassName = classnames(
    styles.alternativeNavbar,
    isScrolled ? styles[`show`] : styles[`hidden`]
  )
  useScrollPos(offset => {
    setScrolled(offset > 1200)
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          currencyCode: "EUR",
          items: [
            {
              name: tour.title,
              id: tour.strapiId,
              price: tour.sessions[0].price,
              brand: "WeShoot",
              category: "Viaggi Fotografici",
            },
          ],
        },
      })

      if (typeof window !== "undefined") {
        if (window.fbq != null) {
          window.fbq("track", "ViewContent", {
            value: tour.sessions[0].price,
            currency: "EUR",
            content_type: "product",
            content_ids: tour.strapiId,
          })
        }
      }
    }
  }, [])

  const openCustomerly = () => {
    const message = `Ciao WeShoot, mi potete dare piú info sul viaggio ${tour.title}?`
    const encodedMessage = encodeURIComponent(message)

    window.open(`https://wa.me/393495269093?text=${encodedMessage}`)

    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "request_info",
      })
    }

    // window.customerly.sendNewMessage(
    //   `Ciao WeShoot, mi potete dare piú info sul viaggio ${tour.title}?`
    // )
  }

  return (
    <>
      <GatsbySeo
        htmlAttributes={{ lang: l.language }}
        title={tour?.SEO?.metaTitle}
        description={tour?.SEO?.metaDescription}
        canonical={absoluteTourLink}
        openGraph={{
          url: absoluteTourLink,
          type: "website",
          title: tour?.SEO?.metaTitle,
          description: tour?.SEO?.metaDescription,
          images: [
            {
              url: tour?.SEO?.shareImage.formats.medium.url,
              width: tour?.SEO?.shareImage.formats.medium.width,
              height: tour?.SEO?.shareImage.formats.medium.height,
              alt: tour?.SEO?.metaTitle,
            },
          ],
          site_name: "WeShoot",
        }}
      />

      <ProductJsonLd
        productName={tour?.SEO?.metaTitle}
        description={tour?.SEO?.metaDescription}
        brand="WeShoot"
        sku={tour?.strapiId}
        mpn={tour?.id}
        images={getImagesURL(tour?.pictures)}
        reviews={getStructuredDataTourReviews(tour)}
        aggregateRating={{
          ratingValue: "5",
          reviewCount: tour?.reviews.length,
        }}
        offers={{
          price: tourPrice,
          priceCurrency: tourCurrency,
          priceValidUntil: moment(tour?.sessions[0].start).format("YYYY-MM-DD"),
          itemCondition: "NewCondition",
          availability: "OnlineOnly",
          url: absoluteTourLink,
          seller: {
            name: "WeShoot",
          },
        }}
      />

      <Header
        img={tour?.image?.localFile.childImageSharp.gatsbyImageData}
        theme="dark"
      >
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className={classnames([styles.header])}>
                <Typography className="animated fadeInDown" variant="heading1">
                  {tour?.title}
                </Typography>
                <Breadcrumbs elements={getTourBreadCrumbs(tour, t, l)} />
                <Button
                  variant={"link"}
                  className={classnames([styles.cta_book])}
                  onClick={() => scrollTo("#sessions")}
                >
                  {t("ui.pages.tour.header.showDepartures")}
                </Button>
                <div>
                  {tour?.reviews.length > 0
                    ? t("ui.pages.tour.header.reviewsCount", {
                        count: tour?.reviews.length,
                      })
                    : ""}{" "}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Header>

      <div className={alternativeNavClassName}>
        <div className={classnames([styles.left])}>
          <GatsbyImage
            className={classnames([styles.icon])}
            image={tour?.image?.localFile.childImageSharp.gatsbyImageData}
            alt={`Viaggio Fotografico ${tour.title}`}
          />
          <div className={classnames([styles.info])}>
            <div className={classnames([styles.title])}>{tour.title}</div>
            {sessions[0] && (
              <div className={classnames([styles.days])}>
                {getTourDays(sessions[0].start, sessions[0].end)}{" "}
                {t("ui.pages.tour.header.days")}
              </div>
            )}
          </div>
        </div>

        <div className={classnames([styles.center])}>
          <Button variant={"linkPlain"} onClick={() => scrollTo("#itinerary")}>
            {t("ui.pages.tour.header.itinerary")}
          </Button>
          {tour.reviews.length > 0 && (
            <Button variant={"linkPlain"} onClick={() => scrollTo("#reviews")}>
              {t("ui.pages.tour.header.reviews")}
            </Button>
          )}
          <Button variant={"linkPlain"} onClick={() => scrollTo("#coaches")}>
            {t("ui.pages.tour.header.coach")}
          </Button>
          <Button variant={"linkPlain"} onClick={() => scrollTo("#faq")}>
            {t("ui.pages.tour.header.faq")}
          </Button>
        </div>

        <div className={classnames([styles.right])}>
          <div className={classnames([styles.info])}>
            <div className={classnames([styles.nextDeparture])}>
              {getTourNextDeparture(tour)}
            </div>
            <div className={classnames([styles.price])}>
              {tourCurrency}
              {tourPrice}
            </div>
          </div>

          <Button variant={"link"} onClick={() => scrollTo("#sessions")}>
            {" "}
            {t("ui.pages.tour.header.showDepartures")}
          </Button>
        </div>
      </div>

      <div className={classnames([styles.tourContainer])}>
        <SocialProofSection />

        <Section
          id={"gallery"}
          className={classnames([styles.gallery])}
          title={t("Sei pronto a scattare foto come queste?")}
          subtitle={t(
            "I coach ti porteranno in questi posti e ti insegneranno a scattare queste foto con la tua macchina fotografica"
          )}
        >
          <GallerySection pictures={tourPictures} />
        </Section>

        <Section
          id={"description"}
          className={classnames([styles.description])}
          variant={"white"}
          title={t("ui.pages.tour.description.title")}
          subtitle={""}
        >
          <Row>
            <Col lg={8} sm={12}>
              <div
                key={tour.tour_id}
                dangerouslySetInnerHTML={createMarkup(tour.description)}
              />
              <div className={classnames([styles.highlights])}>
                {tour.highlights?.map(highlight => {
                  return (
                    <div
                      className={classnames([styles.highlight])}
                      key={highlight.icon.hash}
                    >
                      <GatsbyImage
                        image={
                          highlight.icon.localFile.childImageSharp
                            .gatsbyImageData
                        }
                        alt={highlight.icon.alternativeText}
                        className={classnames([styles.icon])}
                      />
                      <div className={classnames([styles.text])}>
                        <Typography
                          variant="heading5"
                          className={classnames([styles.h5])}
                        >
                          {highlight.title}
                        </Typography>
                        <Typography
                          className={classnames([styles.description])}
                        >
                          {highlight.description}
                        </Typography>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className={classnames([styles.riepilogueCard])}>
                <div className={classnames([styles.header])}>
                  <div className={classnames([styles.duration])}>
                    <div className={classnames([styles.label])}>
                      {t("ui.pages.tour.riepilogueCard.days")}
                    </div>
                    <div className={classnames([styles.number])}>
                      {getTourDays(
                        tour.sessions[0].start,
                        tour.sessions[0].end
                      )}
                    </div>
                  </div>

                  <div className={classnames([styles.price])}>
                    <div className={classnames([styles.label])}>
                      {t("ui.pages.tour.riepilogueCard.balance")}
                    </div>
                    <div className={classnames([styles.number])}>
                      {tourCurrency}
                      {tourPrice}
                    </div>
                  </div>

                  <div className={classnames([styles.next_departure])}>
                    <div className={classnames([styles.label])}>
                      {t("ui.pages.tour.riepilogueCard.nextDeparture")}
                    </div>
                    <div className={classnames([styles.number])}>
                      {getTourNextDeparture(tour)}
                    </div>
                  </div>
                </div>
                <div className={classnames([styles.deposit])}>
                  {t("ui.pages.tour.riepilogueCard.reserve", {
                    amount: `${tourCurrency}${tourDeposit}`,
                  })}
                </div>
                <div
                  className={classnames([styles.whatsincluded])}
                  id={"cosa-incluso"}
                >
                  <Typography variant="heading5">
                    {t("ui.pages.tour.riepilogueCard.whatsincluded")}
                  </Typography>
                  <div className={classnames([styles.includedContainer])}>
                    {tour.whats_includeds?.map(include => {
                      return (
                        <div
                          className={classnames([styles.included])}
                          key={include.id}
                        >
                          {include.icon && (
                            <GatsbyImage
                              image={
                                include.icon?.localFile.childImageSharp
                                  .gatsbyImageData
                              }
                              alt={include.title}
                              className={classnames([styles.icon])}
                            />
                          )}
                          <Typography>{include.title}</Typography>
                        </div>
                      )
                    })}
                  </div>
                  {/*<Typography variant="heading5">Non Include</Typography>*/}
                  {/*{tour.whats_not_includeds?.map(include => {*/}
                  {/*  return (*/}
                  {/*    <div className={classnames([styles.included])} key={include.id}>*/}
                  {/*      {include.icon && (*/}
                  {/*        <GatsbyImage*/}
                  {/*          fluid={include.icon?.localFile.childImageSharp.fluid}*/}
                  {/*          className={classnames([styles.icon])}*/}
                  {/*        />*/}
                  {/*      )}*/}
                  {/*      <Typography>{include.title}</Typography>*/}
                  {/*    </div>*/}
                  {/*  )*/}
                  {/*})}*/}
                </div>

                <div className={classnames([styles.footer])}>
                  <Button
                    variant={"link"}
                    className={classnames([styles.cta_book])}
                    onClick={() => scrollTo("#sessions")}
                  >
                    {t("ui.pages.tour.header.showDepartures")}
                  </Button>
                  <Typography variant={"small"}>
                    {t("ui.pages.tour.riepilogueCard.questions")}
                  </Typography>
                  <Button
                    variant={"outline"}
                    className={classnames([styles.cta_book])}
                    onClick={() => openCustomerly()}
                  >
                    {t("ui.pages.tour.riepilogueCard.chatWithUs")}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Section>

        <Section
          id={"itinerary"}
          className={classnames([styles.itinerary])}
          variant={"grey"}
          title={t("ui.pages.tour.itinerary.title")}
          subtitle={t("ui.pages.tour.itinerary.subtitle")}
        >
          {tourDays?.map((day, index) => {
            return <Day day={day} tour={tour} key={index} />
          })}
        </Section>

        {tour.things_needed && (
          <Section
            className={classnames([styles.thingsNeeded])}
            title={t("ui.pages.tour.thingsNeeded.title")}
            subtitle={t("ui.pages.tour.thingsNeeded.subtitle")}
          >
            <div dangerouslySetInnerHTML={createMarkup(tour.things_needed)} />
          </Section>
        )}

        {tourUsersPictures.length > 0 && (
          <Section
            id={"gallery"}
            className={classnames([styles.gallery])}
            title={t("ui.pages.tour.gallery.title")}
            subtitle={t("ui.pages.tour.gallery.subtitle")}
          >
            <GallerySection pictures={tourUsersPictures} />
          </Section>
        )}

        {tour.reviews.length > 0 && (
          <Section
            className={classnames([styles.reviews])}
            id={"reviews"}
            variant={"grey"}
            title={t("ui.pages.tour.reviews.title")}
            subtitle={t("ui.pages.tour.reviews.subtitle")}
          >
            {tour.reviews?.map(review => {
              return <ReviewCard key={review.id} review={review} tour={tour} />
            })}
          </Section>
        )}

        {sessions[0] && (
          <Section
            className={classnames([styles.coaches_container])}
            variant={"white"}
            id={"coaches"}
            title={t("ui.pages.tour.coaches.title")}
            subtitle={t("ui.pages.tour.coaches.subtitle")}
          >
            {tourCoaches?.map(coach => {
              return <CoachCard coach={coach} key={coach.username} />
            })}
          </Section>
        )}

        {sessions[0] && (
          <Section
            id={"sessions"}
            className={classnames([styles.next_departures])}
            title={t("ui.pages.tour.nextDepartures.title")}
            subtitle={t("ui.pages.tour.nextDepartures.subtitle")}
            variant={"white"}
          >
            {sessions &&
              sessions.map(session => {
                return (
                  <SessionCard session={session} tour={tour} directBooking />
                )
              })}
          </Section>
        )}

        {tour.faqs && (
          <Section
            className={classnames([styles.faq])}
            variant={"grey"}
            id={"faq"}
            title={t("ui.pages.tour.faq.title")}
            subtitle={t("ui.pages.tour.faq.subtitle")}
          >
            {tour.faqs.map(q => {
              return <Faq faq={q} />
            })}
            <FAQJsonLd questions={tour.faqs} />
          </Section>
        )}
      </div>
    </>
  )
}

export default TourPage

export const query = graphql`
  query TourQuery($id: String!) {
    strapiTour(id: { eq: $id }) {
      id
      strapiId
      experience_level
      things_needed
      title
      description
      difficulty
      excerpt
      SEO {
        metaTitle
        metaDescription
        shareImage {
          formats {
            medium {
              url
              width
              height
            }
          }
        }
      }
      reviews {
        created_at
        description
        title
        rating
        user {
          firstName
        }
      }
      sessions {
        end
        id
        sessionId
        start
        status
        maxPax
        balance
        price
        deposit
        currency
        users {
          id
          firstName
          username
          bio
          instagram
          profilePicture {
            localFile {
              ...ImageFragment
            }
          }
        }
      }
      collections {
        name
        slug
        excerpt
        image {
          localFile {
            ...ImageFragment
          }
        }
      }
      image {
        localFile {
          ...ImageFragment
        }
      }
      locations {
        latitude
        longitude
        id
        title
        description
      }
      pictures {
        id
        title
        type
        image {
          localFile {
            ...ImageFragment
          }
          formats {
            medium {
              url
            }
          }
        }
      }
      whats_includeds {
        id
        title
        tour
        icon {
          localFile {
            ...ImageFragment
          }
        }
      }
      whats_not_includeds {
        id
        title
        tour
        icon {
          localFile {
            ...ImageFragment
          }
        }
      }
      states {
        slug
        name
      }
      places {
        slug
        name
      }
      days {
        id
        number
        title
        steps {
          title
          description
          locations {
            latitude
            longitude
            description
            title
            slug
            pictures {
              title
              type
              image {
                localFile {
                  ...ImageFragment
                }
                caption
                name
              }
            }
            state {
              slug
              name
            }
          }
        }
      }
      highlights {
        title
        description
        icon {
          alternativeText
          id
          hash
          localFile {
            ...ImageFragment
          }
        }
      }
      slug
      faqs {
        question
        answer
      }
    }
  }

  fragment ImageFragment on File {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
      thumb: gatsbyImageData(width: 270, height: 270, placeholder: BLURRED)
      full: gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`
