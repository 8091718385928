import React from "react"
import {
    bio,
    coachContainer,
    details,
    icon,
    leftContainer,
    profileContainer,
    weshooterBadge
} from "./index.module.scss"
import Typography from "/src/components/ui/base/typography"
import { useTranslation } from "react-i18next"
import { GatsbyImage } from "gatsby-plugin-image"
import badgeGIF from "/src/images/icons/weshooter.gif"
import { createMarkup } from "../../../../util/tour"
import { PHOTOGRAPHERS_URL } from "../../../../util/constants"
import Link from "../Link"

const CoachCard = ({ coach }) => {
    const {t, i18n:l} = useTranslation();

    return (
        <>
            <div className={coachContainer}>

                <div className={leftContainer}>

                    <div className={profileContainer}>
                         <GatsbyImage image={coach.profilePicture.localFile.childImageSharp.gatsbyImageData} alt={coach.firstName}/>
                    </div>

                    <div className={details}>
                        <Typography variant='heading3' >
                           <Link to={`/${PHOTOGRAPHERS_URL[l.language]}/${coach.username}`} target="_blank">{coach.firstName}</Link>
                        </Typography>
                        <div className={weshooterBadge}>
                            <img src={badgeGIF} className={icon} alt={"Fotografo Certificato WeShoot"}/>
                            <Typography variant={"heading6"} >
                                {t('ui.components.base.CoachCard.qualified')}
                            </Typography>
                        </div>
                        <Typography variant='small' className={bio}>
                            <span dangerouslySetInnerHTML={createMarkup(coach.bio)}/>
                        </Typography>
                    </div>
                </div>

            </div>
        </>
    )

}


export default CoachCard
